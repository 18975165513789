<script>
export default {
  name: 'TpTable',

  props: {
    tableData: {
      type: Array,
      default: () => []
    },

    columns: {
      type: Array,
      default: () => []
    },

    isPage: {
      type: Number,
      default: 1
    },

    totalNum: {
      type: Number,
      default: 0
    },

    currentPage: {
      type: Number,
      default: 1
    },

    pageSize: {
      type: Number,
      default: 50
    },

    isNeedSerialNumber: {
      type: Boolean,
      default: false
    },

    isCheckBox: {
      type: Boolean,
      default: false
    },

    showPagination: {
      type: Boolean,
      default: true
    },

    align: {
      type: String,
      default: 'center'
    },

    height: {
      type: Number,
      default: null
    },

    sortable: {
      type: [Boolean, String],
      default: false
    }
  },

  inject: ['context'],

  render(h) {
    const serialNumberScopedSlots = {}
    serialNumberScopedSlots.default = ({ row, $index }, _) => <span>{(this.currentPage - 1) * this.pageSize + $index + 1}</span>
    return (
        <div class="tp-table">
          <el-table
              height={this.height}
              data={this.tableData}
              header-row-class-name="tp-table__header"
              row-class-name="tp-table_row"
              cell-class-name="tp-table_cell"
              cell-style={this.changeTr}
              onSelection-change={this.isCheckBox ? val => this.handleSelectionChange(val) : ''}
          >
            {this.isCheckBox ? <el-table-column width="80" type="selection" align="center"></el-table-column> : ''}
            {this.isNeedSerialNumber ? (
                <el-table-column
                    minWidth="70"
                    label="序号"
                    align="center"
                    {...{
                      scopedSlots: serialNumberScopedSlots
                    }}
                ></el-table-column>
            ) : (
                ''
            )}
            {this.columns.map(item => {
              const scopedSlots = {}
              if (item.customRender) {
                scopedSlots.default = ({ row }, _, $index) => item.customRender.call(this.context, h, row, item, $index)
              }

              return (
                  <el-table-column
                      key={item.id || item.uuid}
                      label={item.label}
                      prop={item.prop}
                      width={item.width || ''}
                      min-width={item.minWidth || ''}
                      fixed={item.fixed}
                      align={item.align || 'center'}
                      {...{
                        scopedSlots
                      }}
                  ></el-table-column>
              )
            })}
          </el-table>
          {this.isPage && this.showPagination ? (
              <el-pagination
                  class="pagination"
                  layout="total, -> , prev, pager, next, sizes , jumper"
                  {...{
                    on: {
                      'update:currentPage': val => this.$emit('update:currentPage', val),
                      'update:pageSize': val => this.$emit('update:pageSize', val)
                    }
                  }}
                  current-page={this.currentPage}
                  page-sizes={[5, 10, 15, 20]}
                  page-size={this.pageSize}
                  total={this.totalNum}
              ></el-pagination>
          ) : (
              ''
          )}
        </div>
    )
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    handleSizeChange(val) {
      console.log(val)
    },
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 !== 0) {
        return {
          backgroundColor: '#deeaf5'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tp-table /deep/ {
  .tp-table_header {
    height: 48px;

    th {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background-color: #689ad0;
      // border-top: 1px solid #ccc;
      // border-bottom: 1px solid #ccc;
      padding-left: 10px;
    }
  }
  .tp-table_row {
    height: 40px;
    line-height: 40px;
    td {
      padding: 5px 0;
      color: #1a1e25;
      padding-left: 10px;
    }
  }
  .tp-table_cell {
    background-color: #fff;
    .cell {
      p {
        margin: 0;
        color: #1a1e25;
      }
    }
  }
  .pagination {
    display: block;
    margin-top: 20px;
    text-align: left;
    color: #1a1e25;
    .active {
      color: #1b6fee;
      background: rgba(27, 111, 238, 0.1);
      border-radius: 2px;
    }
    .el-input__inner {
      border: none;
      background: #f2f3f5;
      border-radius: 2px;
    }
  }
  .el-table__body tr:hover > td {
    background-color: #fafafa !important;
  }

  .el-table::before {
    display: none !important;
  }
  /* 重现下自带下边框线 */
  .el-table--border {
    border-bottom: 1px solid #fff !important;
  }

  .el-table__fixed {
    height: 100% !important;
  }
  .el-table__fixed-right {
    height: 100% !important;
  }
  .el-table__fixed-body-wrapper {
    top: 48px !important;
  }
}
.pagination /deep/ {
  .active {
    color: #1b6fee;
  }
}
</style>
<style lang="scss" scoped>
/deep/ .el-table__body-wrapper {
  .cell {
    // line-height: 40px;
  }
}
/deep/ .el-table__fixed-body-wrapper {
  .cell {
    // line-height: 40px;
  }
}
</style>
